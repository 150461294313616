<template>
  <div class="oauth-auth-redirect my-5">
    <div class="container">
      <div v-if="loading" class="text-center">
        <b-spinner></b-spinner>
      </div>
      <div v-else class="text-center">
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      processing: false,
      token: null,
    }
  },

  mounted() {
    let error = this.$route.query.error
    let code = this.$route.query.code
    let errorDescription = this.$route.query.error_description
    window.opener.postMessage({
      'a': true,
      'type': 'gt',
      'b': this.encrypt(code),
      'c': error,
      'd': errorDescription,
    }, "*");
  },

  methods: {
  },
}
</script>
  
<style lang="scss" scoped>
.oauth-auth-redirect {
  display: block;
}
</style>